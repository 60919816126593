dmx.slideshow.transitions.slide = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        direction: 'random',

        duration: 600,

        setup: function() {
            this.slider = document.createElement('div');
            this.slider.style.setProperty('position', 'absolute');
            this.slider.style.setProperty('width', '100%');
            this.slider.style.setProperty('height', '100%');
            this.slider.style.setProperty('transition', 'transform ' + this.options.duration + 'ms ease-in');

            this.image1 = document.createElement('div');
            this.image1.style.setProperty('position', 'absolute');
            this.image1.style.setProperty('width', '100%');
            this.image1.style.setProperty('height', '100%');

            this.image2 = this.image1.cloneNode();

            this.image1.style.setProperty('background-size', this.width + 'px');
            this.image1.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
            this.image2.style.setProperty('background-size', this.width + 'px');
            this.image2.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');

            if (this.options.direction === 'random') {
                this.options.direction = ['left', 'right', 'up', 'down'][Math.floor(Math.random() * 4)];
            }

            switch (this.options.direction) {
                case 'left': this.image2.style.setProperty('left', '100%'); break;
                case 'right': this.image2.style.setProperty('left', '-100%'); break;
                case 'up': this.image2.style.setProperty('top', '100%'); break;
                case 'down': this.image2.style.setProperty('top', '-100%'); break;
            }

            this.slider.appendChild(this.image1);
            this.slider.appendChild(this.image2);
            slideshow.effectsContainer.style.setProperty('overflow', 'hidden');
            slideshow.effectsContainer.appendChild(this.slider);
        },

        execute: function() {
            this.slider.addEventListener('transitionend', this.finished.bind(this));
            switch (this.options.direction) {
                case 'left': this.slider.style.setProperty('transform', 'translate(-100%, 0)'); break;
                case 'right': this.slider.style.setProperty('transform', 'translate(100%, 0)'); break;
                case 'up': this.slider.style.setProperty('transform', 'translate(0, -100%)'); break;
                case 'down': this.slider.style.setProperty('transform', 'translate(0, 100%)'); break;
            }
            setTimeout(this.finished.bind(this), this.options.duration);
        },

        after: function() {
            slideshow.effectsContainer.style.removeProperty('overflow');
        }
    }, options));
};
